import { defineComponent, computed, h, Fragment } from 'vue'
import { useRoute } from 'vue-router'
import { useModals } from '../composables'

export default defineComponent({
  name: 'ModalWrapper',
  setup () {
    const { activeModal, closeModal } = useModals()
    const route = useRoute()
    const lastHash = ref('')
    const hasModel = computed(() => activeModal.value?.[0])

    watch(route, (value) => {
      if (
        lastHash.value &&
        activeModal.value &&
        !value.hash.includes(activeModal.value[0]) &&
        lastHash.value.includes(activeModal.value[0])
      ) {
        closeModal(value)
      }

      lastHash.value = value.hash
    })

    return () => hasModel.value
      ? h(activeModal.value[1], activeModal.value[2])
      : h(Fragment)
  }
})
